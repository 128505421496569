import { bindable, customElement, inject, useView, Loader } from 'aurelia-framework';
import { AppContainer }                                     from 'resources/services/app-container';
import { FormSelect2 }                                      from './form-select2';
import { BooleanStatusesRepository }                        from 'modules/administration/boolean-statuses/services/repository';

@inject(AppContainer, BooleanStatusesRepository, Loader)
@customElement('form-boolean-statuses-select2')
@useView('./form-select2.html')
export class FormBooleanStatusesSelect extends FormSelect2 {

    @bindable selectedOption;

    /**
     * Constructor
     *
     * @param appContainer
     * @param booleanStatusesRepository
     * @param loader
     */
    constructor(appContainer, booleanStatusesRepository, loader) {
        super(appContainer, loader);

        this.booleanStatusesRepository = booleanStatusesRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return './form-select2.html';
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        // force use of active user as remote source
        model.element.remoteSource = this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository);

        return super.activate(model);
    }

}
