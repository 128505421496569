import { inject }                                  from 'aurelia-framework';
import { AppContainer }                            from 'resources/services/app-container';
import { FormMaterialUiPicker }                    from './form-material-ui-picker';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React                                       from 'react';
import ReactDOM                                    from 'react-dom';
import MomentUtils                                 from '@date-io/moment';

@inject(AppContainer, React, ReactDOM)
export class FormMaterialUiDatetimePicker extends FormMaterialUiPicker {

    format = 'YYYY-MM-DD HH:mm';

    /**
     * Opens picker
     */
    openPicker() {
        this.picker = ReactDOM.render(
            <MuiPickersUtilsProvider utils={MomentUtils} locale={this.appContainer.i18n.getLocale()}>
                <DateTimePicker
                    open={true}
                    onClose={() => ReactDOM.unmountComponentAtNode(this.materialUiPickerContainer)}
                    onChange={(value) => this.updateModelValue(value)}
                    TextFieldComponent={() => null}
                    value={this.model.value}
                    clearable={true}
                    ampm={false}
                    minutesStep={5}
                    cancelLabel={this.appContainer.i18n.tr('dialog.button.cancel')}
                    clearLabel={this.appContainer.i18n.tr('dialog.button.clear')}
                    okLabel={this.appContainer.i18n.tr('dialog.button.ok')}
                    todayLabel={this.appContainer.i18n.tr('dialog.button.today')}
                />
            </MuiPickersUtilsProvider>,
            this.materialUiPickerContainer,
        );
    }

}
