import { customElement, inject } from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import autosize                  from 'autosize';

@inject(AppContainer)
@customElement('form-textarea')
export class FormTextarea extends BaseComponent {

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        let self = this;

        $('#' + this.modelElementId).closest('.tab-pane').bind('cssClassChanged', function () {
            if ($(this).hasClass('active')) {
                autosize.update($('#' + self.modelElementId));
            }
        });
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {
            let element = $('#' + this.modelElementId);

            setTimeout(() => autosize(element), 0);
        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => {
            let element = $('#' + this.modelElementId);

            setTimeout(() => autosize.destroy(element), 0);
        });
    }
}
