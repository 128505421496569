import { bindable, customElement, inject, Loader, useView } from 'aurelia-framework';
import { AppContainer }                                     from 'resources/services/app-container';
import { FormSelect2 }                                      from './form-select2';
import { ManagingEntitiesRepository }                       from 'modules/entities/managing-entities/services/repository';
import { ControllingEntitiesRepository }                    from 'modules/entities/controlling-entities/services/repository';
import { InspectingEntitiesRepository }                     from 'modules/entities/inspecting-entities/services/repository';
import { MaintenanceCompaniesRepository }                   from 'modules/entities/maintenance-companies/services/repository';
import { InstallingCompaniesRepository }                    from 'modules/entities/installing-companies/services/repository';
import { OwnersRepository }                                 from 'modules/entities/owners/services/repository';
import { EntityType }                                       from 'modules/management/models/entity-type';
import collect                                              from 'collect.js';

@useView('./form-select2.html')
@customElement('form-entities-select')
@inject(
    AppContainer,
    ManagingEntitiesRepository,
    ControllingEntitiesRepository,
    InspectingEntitiesRepository,
    MaintenanceCompaniesRepository,
    InstallingCompaniesRepository,
    OwnersRepository,
    Loader,
)
export class FormEntitiesSelect extends FormSelect2 {

    @bindable selectedOption;

    repositoriesMapping = collect([]);

    /**
     * Constructor
     *
     * @param appContainer
     * @param managingEntitiesRepository
     * @param controllingEntitiesRepository
     * @param inspectingEntitiesRepository
     * @param maintenanceCompaniesRepository
     * @param installingCompaniesRepository
     * @param ownersRepository
     * @param loader
     */
    constructor(
        appContainer,
        managingEntitiesRepository,
        controllingEntitiesRepository,
        inspectingEntitiesRepository,
        maintenanceCompaniesRepository,
        installingCompaniesRepository,
        ownersRepository,
        loader,
    ) {
        super(appContainer, loader);

        this.repositoriesMapping.put(EntityType.MANAGING_ENTITY, managingEntitiesRepository);
        this.repositoriesMapping.put(EntityType.CONTROLLING_ENTITY, controllingEntitiesRepository);
        this.repositoriesMapping.put(EntityType.INSPECTING_ENTITY, inspectingEntitiesRepository);
        this.repositoriesMapping.put(EntityType.MAINTENANCE_COMPANY, maintenanceCompaniesRepository);
        this.repositoriesMapping.put(EntityType.INSTALLING_COMPANY, installingCompaniesRepository);
        this.repositoriesMapping.put(EntityType.OWNER, ownersRepository);
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        model.element.remoteSource =
            () => model.element.entityTypeId
                ? this.repositoriesMapping.get(model.element.entityTypeId).active()
                : Promise.resolve([]);

        return super.activate(model);
    }

    /**
     * Changes entity type
     *
     * @param entityTypeId
     *
     * @returns {*}
     */
    changeEntityType(entityTypeId) {
        this.model.element.entityTypeId = entityTypeId;

        return this.fetchData();
    }
}
