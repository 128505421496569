import { customElement } from 'aurelia-framework';

@customElement('form-actions')
export class FormActions {

    actions = [];

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model = model;

        this.actions = this.model.element.actions;
    }

}
