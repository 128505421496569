import { bindable, bindingMode, computedFrom } from 'aurelia-framework';

export class FormField {

    @bindable({defaultBindingMode: bindingMode.twoWay}) formId;
    @bindable({defaultBindingMode: bindingMode.twoWay}) element;
    @bindable({defaultBindingMode: bindingMode.twoWay}) value;

    viewModels = {
        text:                          './components/form-input',
        checkbox:                      './components/form-checkbox',
        button:                        './components/form-input',
        color:                         './components/form-input',
        date:                          './components/form-input',
        datetime:                      './components/form-input',
        'datetime-local':              './components/form-input',
        email:                         './components/form-input',
        month:                         './components/form-input',
        number:                        './components/form-input',
        password:                      './components/form-input',
        'file-dropzone':               './components/form-file-dropzone',
        file:                          './components/form-file',
        range:                         './components/form-input',
        search:                        './components/form-input',
        tel:                           './components/form-input',
        time:                          './components/form-input',
        url:                           './components/form-input',
        week:                          './components/form-input',
        options:                       './components/form-select',
        buttons:                       './components/form-actions',
        undefined:                     './components/form-input',
        null:                          './components/form-input',
        int:                           './components/form-input',
        integer:                       './components/form-input',
        float:                         './components/form-input',
        string:                        './components/form-input',
        bool:                          './components/form-checkbox',
        boolean:                       './components/form-checkbox',
        actions:                       './components/form-actions',
        textarea:                      './components/form-textarea',
        select2:                       './components/form-select2',
        'bootstrap-multiselect':       './components/form-bootstrap-multiselect',
        'multiselect-native':          './components/form-multiselect-native',
        'user-multiselect':            './components/form-user-multiselect',
        'users-bootstrap-multiselect': './components/form-users-bootstrap-multiselect',
        'bootstrap-datetimepicker':    './components/form-bootstrap-datetimepicker',
        'pickadate':                   './components/form-pickadate',
        'pickatime':                   './components/form-pickatime',
        'daterangepicker':             './components/form-daterangepicker',
        'color-picker':                './components/form-color-picker',
        'fancytree':                   './components/form-fancytree',
        'duallistbox':                 './components/form-duallistbox',
        'users-duallistbox':           './components/form-users-duallistbox',
        'tags':                        './components/form-tags',
        'summernote':                  './components/form-summernote',
        'quill':                       './components/form-quill',
        'labelsorter':                 './components/form-labelsorter',
        'users-select':                './components/form-user-select2',
        'boolean-options-select':      './components/form-boolean-options-select2',
        'boolean-statuses-select':     './components/form-boolean-statuses-select2',
        'districts-select':            './components/form-districts-select2',
        'counties-select':             './components/form-counties-select2',
        'parishes-select':             './components/form-parishes-select2',
        'custom-select':               './components/form-custom-select2',
        'slider':                      './components/form-slider',
        'non-sequential-date':         './components/form-nonsequentialdate',
        'datetimepicker':              './components/form-datetimepicker',
        'acbin-duallistbox':           './components/form-acbin-duallistbox',
        'custom-multiple-select':      './components/form-custom-multiple-select',
        'select2-native':              './components/form-select2-native',
        'months-select2':              './components/form-months-select2',
        'years-select2':               './components/form-years-select2',
        'smiley-rating':               './components/form-smiley-rating',
        'int-checkbox':                './components/form-int-checkbox',
        'material-ui-date-picker':     './components/form-material-ui-date-picker',
        'material-ui-time-picker':     './components/form-material-ui-time-picker',
        'material-ui-datetime-picker': './components/form-material-ui-datetime-picker',
        'entities-select':             './components/form-entities-select2',
    };

    /**
     * Constructor
     */
    constructor() {
        this.formField = this;
    }

    /**
     * Returns the string that points to the template file of that specific form
     * element type.
     *
     * @returns {string}
     */
    @computedFrom('element')
    get viewModel() {
        let viewModel = this.viewModels[this.element.type];

        if (typeof viewModel === 'undefined' || viewModel === null) {
            console.log('Element type is invalid');
        } else if (viewModel instanceof Object) {
            if (viewModel.condition()) {
                viewModel = viewModel.fallback;
            } else {
                viewModel = viewModel.name;
            }
        }

        return viewModel;
    }

}
