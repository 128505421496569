import { inject, useView } from 'aurelia-framework';
import { AppContainer }    from 'resources/services/app-container';
import { FormDuallistbox } from './form-duallistbox';
import { UsersRepository } from 'modules/administration/users/services/repository';
import 'assets/js/plugins/forms/inputs/duallistbox.min';

@inject(AppContainer, UsersRepository)
@useView('./form-duallistbox.html')
export class FormUsersDuallistbox extends FormDuallistbox {

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer, usersRepository) {
        super(appContainer);

        this.usersRepository = usersRepository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        model.element.remoteSource = this.usersRepository.active.bind(this.usersRepository);

        return super.activate(model);
    }

}
