import { bindable, customElement, inject } from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseComponent }                   from 'resources/elements/aurelia-form/components/base-component';
import 'summernote';
import 'summernote/lang/summernote-pt-PT'; // commented - if summernote fixes these translations just uncomment again
// import 'assets/js/plugins/forms/summernote_translations/summernote-pt-PT';

@inject(AppContainer)
@customElement('form-summernote')
export class FormSummernote extends BaseComponent {

    editor = null;

    @bindable customSettings = {};
    @bindable height         = null;
    @bindable toolbar        = [
        ['style', ['style', 'bold', 'clear']],
        ['color', ['color']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['layout', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'table', 'hello', 'picture']],
        ['misc', ['undo', 'redo', 'fullscreen']], // , 'codeview' <- source view button, removed
    ];

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;

        this.customSettings = this.model.element.settings ? this.model.element.settings : {};

        return super.activate(model);
    }

    /**
     * Creates elements
     */
    createElement() {
        this.editor = $(`#${this.modelElementId}`);

        this.editor.data('view-model', this);

        this.editor.summernote({
            height:    this.height,
            toolbar:   this.toolbar,
            lang:      this.appContainer.i18n.getLocale() === 'pt' ? 'pt-PT' : 'en',
            callbacks: {
                onChange: (contents) => {
                    this.model.value = contents;
                },
            },
        });

        this.editor.summernote('code', this.model.value);
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => this.editor.summernote('destroy'));
    }

}
