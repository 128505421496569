import { bindable, inject } from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { BaseComponent }    from 'resources/elements/aurelia-form/components/base-component';
import 'assets/js/plugins/forms/selects/bootstrap-multiselect';

@inject(AppContainer)
export class FormBootstrapMultiselect extends BaseComponent {

    @bindable settings;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Handles activate event
     *
     * @param model
     */

    activate(model) {
        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;

        this.model.element.attributes = this.model.element.attributes || {};

        this.settings = this.model.element.settings || {};

        if (!(this.model.element.options && this.model.element.options instanceof Array)) {
            this.model.element.options = [];
        }

        if (!(this.model.value && this.model.value instanceof Array)) {
            this.model.value = [];
        }

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData();
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     */
    fetchData() {
        let parameters = {};

        this.model.element.options.splice(0, this.model.element.options.length);

        if (this.model.element.remoteSourceParameters instanceof Function) {
            parameters = this.model.element.remoteSourceParameters();

            if (!parameters) {
                return Promise.resolve([]);
            }
        }

        return this.model.element.remoteSource(parameters)
            .then((response) => {
                if (this.model.element.processResults instanceof Function) {
                    for (let i = 0; i < response.length; i++) {
                        this.model.element.options.push(this.model.element.processResults(response[i]));
                    }
                } else {
                    this.model.element.options.splice(0, this.model.element.options.length, ...response);
                }

                return response;
            });
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {
            let self        = this;
            let htmlElement = $('#' + this.modelElementId);

            $(htmlElement).multiselect({
                selectedClass:                  null,
                includeSelectAllOption:         true,
                selectAllText:                  self.appContainer.i18n.tr('text.bootstrap-multiselect.select-all-text'),
                allSelectedText:                self.appContainer.i18n.tr('text.bootstrap-multiselect.all-selected-text'),
                nonSelectedText:                self.appContainer.i18n.tr('text.bootstrap-multiselect.non-selected-text'),
                nSelectedText:                  self.appContainer.i18n.tr('text.bootstrap-multiselect.n-selected-text'),
                filterPlaceholder:              self.appContainer.i18n.tr('text.bootstrap-multiselect.filter-placeholder'),
                enableFiltering:                self.settings.filterable || true,
                enableCaseInsensitiveFiltering: self.settings.filterable || true,
                enableReplaceDiacritics:        true, // TODO - CURRENTLY NOT AVAILABLE - VIEW ISSUE AT https://github.com/davidstutz/bootstrap-multiselect/pull/725
                numberDisplayed:                3,
                onInitialized:                  (select, container) => select.prop('tabindex', -1),
                onDropdownShown:                (event) => htmlElement.parent().find('input[type="text"].multiselect-search').focus(),
            }).on('change', (event) => {
                if (event.originalEvent) {
                    return;
                }

                let notice = new CustomEvent('change', {
                    bubbles: false,
                });

                $(htmlElement)[0].dispatchEvent(notice);

                this.updateCheckboxes();
            });

            this.initiateCheckboxes();
            this.toggleDisabledProperty();
        });
    }

    /**
     * Rebuilds element
     */
    rebuildElement() {
        return this.simplePromise(() => {
            $('#' + this.modelElementId).multiselect('rebuild');

            this.initiateCheckboxes();
        });
    }

    /**
     * Refreshes element
     */
    refreshElement() {
        return this.simplePromise(() => {
            $('#' + this.modelElementId).multiselect('refresh');

            this.updateCheckboxes();
        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => $('#' + this.modelElementId).multiselect('destroy'));
    }

    /**
     * Initiates checkboxes
     */
    initiateCheckboxes() {
        $('.multiselect-container input').uniform();
    }

    /**
     * Updates checkboxes
     */
    updateCheckboxes() {
        $.uniform.update($('.multiselect-container input'));
    }

    /**
     * Returns selected attribute
     *
     * @param option
     *
     * @returns {string} 'select' if option === model, otherwise returns ''
     */
    selectedAttribute(option) {
        if (this.model.value === null || typeof this.model.value === 'undefined') {
            return '';
        }

        return this.model.value.indexOf(option) > -1 ? 'selected' : '';
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        super.subscribeEventListeners();

        // subscribes `form-element-options-updated` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('form-element-options-updated', (elementId) => {
                if (!elementId || elementId === this.modelElementId) {
                    this.rebuildElement();
                }
            }),
        );
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes `model.value` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.value)
                .subscribe(() => this.refreshElement()),
        );

        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.element.options)
                .subscribe(() => this.rebuildElement()),
        );

        // subscribes `settings.disabled` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model.element.attributes, 'disabled')
                .subscribe(() => this.toggleDisabledProperty()),
        );
    }

    /**
     * Handles disabled property
     */
    toggleDisabledProperty() {
        return this.simplePromise(() => {
            let action = this.model.element.attributes.disabled === true ? 'disable' : 'enable';

            $('#' + this.modelElementId).multiselect(action);
        });
    }

    /**
     * Changes model element options
     *
     * @param newOptions
     */
    replaceOptions(newOptions) {
        this.model.element.options.splice(0, this.model.element.options.length, ...newOptions);
    }

}
