import { bindable, computedFrom, containerless, inject } from 'aurelia-framework';
import { AppContainer }                                  from 'resources/services/app-container';
import collect                                           from 'collect.js';

@containerless
@inject(AppContainer)
export class FormGroup {

    observers = [];

    @bindable element;
    @bindable required;

    /**
     * Add here components that shall have fixed label
     *
     * @type {[]}
     */
    fixedLabelComponents = [
        'acbin-duallistbox',
        'bootstrap-multiselect',
        'checkbox',
        'file-dropzone',
        'multiselect-native',
        'quill',
        'slider',
        'smiley-rating',
        'summernote',
        'tags',
    ];

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.required = this.element.required !== null && typeof this.element.required !== 'undefined' ? this.element.required : true;

        this.subscribeObservers();
    }

    /**
     * Handles unbind event
     */
    unbind() {
        this.disposeObservers();
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes `element.required` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.element, 'required')
                .subscribe(() => this.required = this.element.required),
        );
    }

    /**
     * Disposes observers
     */
    disposeObservers() {
        while (this.observers.length) {
            this.observers.pop().dispose();
        }

        this.observers.length = 0;
    }

    /**
     * Users can set label to false if they do not want to show the label
     *
     * @returns {boolean} true if label is not false
     */
    @computedFrom('element')
    get showsLabel() {
        return this.element.label !== false && this.element.showLabel !== false;
    }

    // /**
    //  * Returns whether element is required
    //  *
    //  * @returns {boolean}
    //  */
    // @computedFrom('element')
    // get required() {
    //     return this.element.required !== null && typeof this.element.required !== 'undefined'
    //            ? this.element.required
    //            : true;
    // }

    /**
     * Returns label class
     *
     * @returns {string}
     */
    @computedFrom('element')
    get labelClass() {
        let fixedLabelComponents = collect(this.fixedLabelComponents);

        return fixedLabelComponents.contains(this.element.type) ? 'fixed-label is-visible' : 'control-label';
    }

}
