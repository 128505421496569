import { bindable, inject, Loader, PLATFORM } from 'aurelia-framework';
import { AppContainer }                       from 'resources/services/app-container';
import { BaseComponent }                      from 'resources/elements/aurelia-form/components/base-component';
import { BooleanStatus }                      from 'modules/administration/models/boolean-status';
import { select2 }                            from 'select2';

@inject(AppContainer, Loader)
export class FormSelect2 extends BaseComponent {

    defaultSettings     = {
        allowClear: true,
        width:      '100%',
    };
    defaultAjaxSettings = {
        minimumInputLength: 2,
        ajax:               {
            dataType:       'json',
            delay:          250,
            data:           function (params) {
                return {
                    query: params.term, // search term
                    page:  params.page || 1,
                    limit: params.limit || 10,
                };
            },
            processResults: function (data, params) {
                params.page  = params.page || 1;
                params.limit = params.limit || 10;

                return {
                    results:    $.map(data.items, function (item) {
                        return {
                            id:   item.id,
                            text: item.name,
                        };
                    }),
                    pagination: {
                        more: (params.page * 10) < data.total_count,
                    },
                };
            },
            cache:          true,
        },
    };

    @bindable selectedOption;

    firstCreation          = true;
    originalSelectedOption = null;

    /**
     * Constructor
     *
     * @param appContainer
     * @param loader
     */
    constructor(appContainer, loader) {
        super(appContainer);

        this.loader = loader;
    }

    /**
     * Handles change of selected option
     *
     * @param newValue
     * @param oldValue
     */
    selectedOptionChanged(newValue, oldValue) {
        if (newValue !== oldValue) {
            this.model.value = newValue;
        }
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.selectedOption         = model.value;
        this.originalSelectedOption = model.value;

        if (!(model.element.options && model.element.options instanceof Array)) {
            model.element.options = [];
        }

        return this.loadSelect2Language().then(() => super.activate(model));
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.resetPlaceholder();
    }

    /**
     * Loads select2 language
     */
    loadSelect2Language() {
        // TODO: check how to dynamically load localization files
        //return this.loader.loadModule('select2/dist/js/i18n/' + this.appContainer.i18n.getLocale()).then(() => {
        return this.loader.loadModule(PLATFORM.moduleName('select2/dist/js/i18n/pt')).then(() => {
            this.defaultSettings.language = this.appContainer.i18n.getLocale();

            return true;
        });
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     */
    fetchData() {
        return this.isAjaxRemoteSource() ? this.fetchFromAjaxRemoteSource() : this.fetchFromRegularRemoteSource();
    }

    /**
     * Fetches data from regular remote source
     *
     * @returns {*}
     */
    fetchFromRegularRemoteSource() {
        let parameters = {};

        this.model.element.options.splice(0, this.model.element.options.length);

        if (this.model.element.remoteSourceParameters instanceof Function) {
            parameters = this.model.element.remoteSourceParameters();
        }

        return parameters
            ? this.model.element.remoteSource(parameters).then((response) => this.handleFetchResponse(response))
            : Promise.resolve([]);
    }

    /**
     * Fetches data from ajax remote source
     *
     * @returns {*}
     */
    fetchFromAjaxRemoteSource() {
        return this.model.value
            ? this.model.element.settings.ajax.find(this.model.value).then((response) => this.handleFetchResponse(response))
            : Promise.resolve([]);
    }

    /**
     * Handles fetch response
     *
     * @returns {*}
     */
    handleFetchResponse(response) {
        let processResults = this.model.element.processResults instanceof Function;

        for (let i = 0; i < response.length; i++) {
            this.model.element.options.push(processResults ? this.model.element.processResults(response[i]) : response[i]);
        }

        return response;
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {
            let htmlElement = $('#' + this.modelElementId);
            let settings    = this.composeSettings();

            $(htmlElement).select2(settings)
                .on('change', (event) => {
                    // don't propagate endlessly
                    if (event.originalEvent) {
                        return;
                    }
                    // dispatch to raw select within the custom element
                    let notice = new CustomEvent('change', {
                        bubbles: true,
                    });

                    $(htmlElement)[0].dispatchEvent(notice);
                })
                .on('select2:close', (event) => htmlElement.data('select2').$selection.focus());

            if (this.firstCreation) {
                $(htmlElement).val(this.originalSelectedOption).trigger('change');

                this.firstCreation = false;
            }
        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => $('#' + this.modelElementId).select2('destroy'));
    }

    /**
     * Resets placeholder
     */
    resetPlaceholder() {
        // TODO - code below is just temporally, opened issue #4566 at https://github.com/select2/select2/issues/4566
        let placeholder = $('#' + this.modelElementId).attr('data-placeholder');

        $('#select2-' + this.modelElementId + '-container').find('.select2-selection__placeholder').html(placeholder);
    }

    /**
     * Returns selected attribute
     *
     * @param option
     *
     * @returns {string} 'select' if option == model, otherwise returns ''
     */
    selectedAttribute(option) {
        return option === this.selectedOption ? 'selected' : '';
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `form-element-options-updated` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('form-element-options-updated', (elementId) => {
                if (!elementId || elementId === this.modelElementId) {
                    // destroy & recreate element
                    this.destroyElement().then(() => this.createElement());
                }
            }),
        );

        // subscribes `locale-changed` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('locale-changed', () => {
                this.fetchData()
                    .then((response) => this.loadSelect2Language())
                    .then((response) => this.destroyElement())
                    .then((response) => this.createElement());
            }),
        );

        // subscribes `form-reseted` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('form-reseted', (formId) => {
                if (formId === this.model.formId) {
                    if (!this.model.value || !this.model.value.toString().length) {
                        // let element = $('#' + this.modelElementId);

                        // TODO: ROB - Not entirely sure it is needed, reevaluate
                        // this.appContainer.theme.resetFloatingLabel(element);
                    }
                }
            }),
        );
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes model.value property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model, 'value')
                .subscribe((newValue, oldValue) => {
                    this.evaluateFloatingLabel(newValue);

                    if (newValue !== oldValue) {
                        $('#' + this.modelElementId).val(newValue).trigger('change');
                    }

                    this.resetPlaceholder();
                }),
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model.element, 'required')
                .subscribe(() => this.resetPlaceholder()),
        );
    }

    /**
     * Checks if it has an ajax remote source
     *
     * @returns {boolean}
     */
    isAjaxRemoteSource() {
        let settings = this.model.element.settings;

        if (typeof settings !== 'undefined' && settings !== null) {
            return typeof settings.ajax !== 'undefined' && settings.ajax !== null;
        }

        return false;
    }

    /**
     * Composes select2 settings
     */
    composeSettings() {
        let settings = this.model.element.settings || {};

        settings.placeholder = this.appContainer.i18n.tr(this.model.element.label);

        if (this.isAjaxRemoteSource()) {
            let intermediateSettings = $.extend(true, this.defaultAjaxSettings, settings);

            return $.extend({}, this.defaultSettings, intermediateSettings);
        }

        return $.extend({}, this.defaultSettings, settings);
    }

    /**
     * Return the selected record, if applicable
     *
     * @returns {*}
     */
    selectedRecord() {
        return this.model.element.options.find(option => String(option.id) === String(this.selectedOption));
    }

    /**
     * Checks if the option should be disabled
     * This should only happen for cacheable resources on the `inactive` state
     *
     * @param option
     */
    disableOption(option) {
        return typeof option.status_id !== 'undefined' && option.status_id === BooleanStatus.INACTIVE;
    }

}
