import { customElement, inject } from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import Quill                     from 'quill';

@inject(AppContainer)
@customElement('form-quill')
export class FormQuill extends BaseComponent {

    // the editor instance
    editor = null;

    // the default toolbar and corresponding handlers, these can be extended
    handlers = {};
    toolbar  = [
        [{ 'font': [] }, { 'size': [] }],
        ['bold', 'italic', 'strike', 'underline', { 'color': [] }, { 'background': [] }],
        [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        ['image'],
        ['clean'],
    ];

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;

        // assign the component's attributes and settings
        this.assignAttributes();
        this.assignAdditionalSettings();

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData();
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.model.value && this.editor.setContents(JSON.parse(this.model.value));
    }

    /**
     * Creates element
     */
    createElement() {
        this.editor = new Quill(`#${this.modelElementId}`, {
            modules:     {
                toolbar: {
                    container: this.toolbar,
                    handlers:  this.handlers,
                },
            },
            placeholder: '',
            readOnly:    this.model.element.attributes.readonly || false,
            theme:       'snow',
        });

        this.editor.on('text-change', (delta, oldDelta, source) => this.model.value = JSON.stringify(this.editor.getContents()));
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => { /* it autodestroys */ });
    }

    /**
     * Assigns the default `attributes` if none were given
     */
    assignAttributes() {
        if (typeof this.model.element.attributes === 'undefined') {
            this.model.element.attributes = {};
        }
    }

    /**
     * Assigns any additional settings given, specifically the `toolbar` and `handler` extensions
     */
    assignAdditionalSettings() {
        if (typeof this.model.element.settings !== 'undefined') {
            this.model.element.settings.toolbar instanceof Array && this.toolbar.push(...this.model.element.settings.toolbar);

            this.handlers = this.model.element.settings.handlers instanceof Object
                ? $.extend(true, this.handlers, this.model.element.settings.handlers)
                : this.handlers;
        }
    }

}
