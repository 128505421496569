import { inject }        from 'aurelia-framework';
import { BaseComponent } from 'resources/elements/aurelia-form/components/base-component';
import { AppContainer }  from 'resources/services/app-container';
import { pickadate }     from 'pickadate/lib/picker.date';
import { pickatime }     from 'pickadate/lib/picker.time';
import 'pickadate/lib/translations/pt_PT';

@inject(AppContainer)
export class FormPickadate extends BaseComponent {

    defaultSettings = {
        format:       'yyyy-mm-dd',
        selectYears:  false,
        selectMonths: false,
        editable:     false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {
            let htmlElement = $('#' + this.modelElementId);

            let settings = this.composeSettings();

            $(htmlElement)
                .pickadate(settings)
                .on('change', function (event) {
                    if (event.originalEvent) {
                        return;
                    }

                    let notice = new CustomEvent('change', {
                        bubbles: false,
                    });

                    $(htmlElement)[0].dispatchEvent(notice);
                });

            // If there is already an input group defined, move the dom elements created by pickadate to it
            let parents = $(htmlElement).parents('.input-group');

            if (parents.length === 2) {
                let top = $(parents[0]).detach();

                $(parents[1]).prepend($(top).children());
            }
        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => $('#' + this.modelElementId).stop());
    }

    /**
     * Composes select2 settings
     */
    composeSettings() {
        let settings = this.model.element.settings || {};

        return $.extend({}, this.defaultSettings, settings);
    }
}
