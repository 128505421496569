import { bindable, customElement, inject } from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseComponent }                   from 'resources/elements/aurelia-form/components/base-component';

import 'jquery.fancytree/dist/modules/jquery.fancytree.edit';
import 'jquery.fancytree/dist/modules/jquery.fancytree.filter';
import 'jquery.fancytree/dist/modules/jquery.fancytree.persist';

@inject(AppContainer)
@customElement('form-fancy-tree')
export class FormFancytree extends BaseComponent {

    @bindable customSettings;

    tree;
    firstCreation   = true;
    defaultSettings = {
        checkbox:   true,
        selectMode: 2,
        select:     this.treeNodeSelected.bind(this),
    };

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Handles activate event
     *
     * @param model
     */

    activate(model) {
        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;
        this.customSettings = this.model.element.settings ? this.model.element.settings : {};

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData();
    }

    /**
     * Handles attached event
     */
    createElement() {
        return this.simplePromise(() => {
            let settings = this.handleSettings();

            this.tree = $('#' + this.modelElementId).fancytree(settings);

            if (this.customSettings.editable === true) {
                $(this.tree)
                    .on('nodeCommand', this.nodeCommandCallback())
                    .on('keydown', this.keydownCallback());
            }

            this.selectInitialNodes();
            this.firstCreation = false;
        });
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        super.subscribeEventListeners();

        // subscribes `form-element-options-updated` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('form-element-options-updated', (elementId) => {
                if (!elementId || elementId === this.modelElementId) {
                    // destroy & recreate element
                    this.destroyElement().then(() => this.createElement());
                }
            }),
        );
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes `model.value` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.value)
                .subscribe(() => this.selectInitialNodes()),
        );
    }

    /**
     * Selects initial nodes
     */
    selectInitialNodes() {
        if (this.firstCreation) {
            let modelValues = this.model.value.slice(0);

            $(this.tree).fancytree('getTree').visit((node) => {
                let nodeKey  = parseInt(node.key, 10);
                let selected = modelValues.indexOf(nodeKey) >= 0;

                node.setSelected(selected);

                if (selected && this.customSettings.disableSelected) {
                    node.unselectable       = true;
                    node.unselectableStatus = true;
                }

                if ((selected && this.customSettings.disableSelected) || (this.model.element.attributes && this.model.element.attributes.disabled)) {
                    node.unselectable       = true;
                    node.unselectableStatus = true;
                }
            });
        }
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     */
    fetchData() {
        let parameters = {};

        if (typeof this.model.element.remoteSourceParameters === 'function') {
            parameters = this.model.element.remoteSourceParameters();
        }

        return this.model.element.remoteSource(parameters).then((response) => this.customSettings.source = response);
    }

    /**
     * Handles settings
     */
    handleSettings() {
        let settings = $.extend({}, this.defaultSettings, this.customSettings);

        if (settings.editable === true) {
            settings.extensions = ['edit'];

            settings.edit = {
                close: (event, data) => this.handleEdition(data),
            };
        }

        return settings;
    }

    /**
     * Handles node edition
     *
     * @param data
     */
    handleEdition(data) {
        let node = data.node;

        if (typeof node !== 'undefined' && node !== null) {
            let parent_id = node.parent.key;

            let nodeData = {
                name:      node.title,
                parent_id: parseInt(parent_id, 10) ? parent_id : null,
                status_id: 1,
            };

            if (data.save && data.isNew) {
                this.customSettings.repository.create(nodeData).then((response) => {
                    if (response.status === true) {
                        node.key = response.model.id;
                    }
                });
            }

            if (data.save && !data.isNew) {
                this.customSettings.repository.update(node.key, nodeData);
            }
        }
    }

    /**
     * Handles tree node select event
     *
     * @param event
     * @param data
     */
    treeNodeSelected(event, data) {
        let selectedNodes = $.map(data.tree.getSelectedNodes(), function (node) {
            return parseInt(node.key, 10);
        });

        this.model.value.splice(0, this.model.value.length, ...selectedNodes);
    }

    collapseAll() {
        $(this.tree).fancytree('getRootNode').visit((node) => node.setExpanded(false));
    }

    expandAll() {
        $(this.tree).fancytree('getRootNode').visit((node) => node.setExpanded(true));
    }

    addChild() {
        $(this.tree).trigger('nodeCommand', { cmd: 'addChild' });
    }

    addSibling() {
        $(this.tree).trigger('nodeCommand', { cmd: 'addSibling' });
    }

    editNode() {
        $(this.tree).trigger('nodeCommand', { cmd: 'editNode' });
    }

    removeNode(node) {
        this.appContainer.swalService.customAlert({
                title:              this.appContainer.i18n.tr('form.title.delete-access'),
                text:               this.appContainer.i18n.tr('form.confirm.delete-access') + '?',
                type:               'warning',
                showCancelButton:   true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText:  this.appContainer.i18n.tr('form.button.delete'),
                closeOnConfirm:     true,
            },
            () => {
                // do an AWESOME request here to remove the node in backend!!
                node.remove();
            });
    }

    /**
     * Returns node command callback
     *
     * @returns {function(*, *)}
     */
    nodeCommandCallback() {
        return (event, data) => {
            let node = $(this.tree).fancytree('getTree').getActiveNode();

            if (typeof node !== 'undefined' && node !== null) {
                switch (data.cmd) {
                    case 'addChild':
                        let newNode = node.editCreateNode('child', '');
                        break;
                    case 'addSibling':
                        node.editCreateNode('after', '');
                        break;
                    case 'editNode':
                        node.editStart();
                        break;
                    case 'removeNode':
                        this.removeNode(node);
                        break;
                    default:
                        alert('Unhandled command: ' + data.cmd);
                        return;
                }
            }
        };
    }

    /**
     * Returns node command callback
     *
     * @returns {function(*, *)}
     */
    keydownCallback() {
        return (event) => {
            let command = null;

            switch ($.ui.fancytree.eventToString(event)) {
                case 'ctrl+shift+m':
                case 'meta+shift+m': // mac: cmd+shift+m
                    command = 'addChild';
                    break;
                case 'ctrl+m':
                case 'meta+m': // mac
                    command = 'addSibling';
                    break;
                case 'del':
                    command = 'removeNode';
                    break;
            }

            if (command) {
                $(this.tree).trigger('nodeCommand', {cmd: command});
                // e.preventDefault();
                // e.stopPropagation();
                return false;
            }
        };
    }

}
