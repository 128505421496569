import { inject, useView }       from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { FormMultiselectNative } from './form-multiselect-native';
import { UsersRepository }       from 'modules/administration/users/services/repository';

@inject(AppContainer, UsersRepository)
@useView('./form-multiselect-native.html')
export class FormUserMultiselect extends FormMultiselectNative {

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer, usersRepository) {
        super(appContainer);

        this.usersRepository = usersRepository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        model.element.remoteSource = this.usersRepository.active.bind(this.usersRepository);

        return super.activate(model);
    }

}
